<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t("Journal de stock") }}
      </v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="search"
            :placeholder="$t('Rechercher par le nom')"
            :label="$t('Rechercher par le nom')"
            outlined
            dense
            hide-default-footer
            hide-details
          />
        </v-col>
      </v-row>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-menu
            ref="dateMenu"
            v-model="dateMenu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFrom"
                clearable
                :label="$t('DU')"
                placeholder=""
                outlined
                dense
                hide-details
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="dateFrom"
              range
              :active-picker.sync="activePicker"
            />
            <v-card-actions>
              <v-btn
                text
                small
                @click.stop="dateMenu1 = false"
              >
                {{ $t("Fermer") }}
              </v-btn>
              <v-spacer />
              <v-btn
                depressed
                color="primary"
                small
                @click.stop="dateMenu1 = false"
              >
                {{ $t("Ok") }}
              </v-btn>
            </v-card-actions>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
        >
          <v-menu
            ref="dateMenu"
            v-model="dateMenu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateTo"
                clearable
                :label="$t('AU')"
                placeholder=""
                outlined
                dense
                hide-details
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="dateTo"
              range
              :active-picker.sync="activePicker"
            />
            <v-card-actions>
              <v-btn
                text
                small
                @click.stop="dateMenu2 = false"
              >
                {{ $t("Fermer") }}
              </v-btn>
              <v-spacer />
              <v-btn
                depressed
                color="primary"
                small
                @click.stop="dateMenu2 = false"
              >
                {{ $t("Ok") }}
              </v-btn>
            </v-card-actions>
          </v-menu>
        </v-col>
      </v-row>
      <v-spacer />
      <v-data-table
        v-model="selected"
        :loading-text="$t('Chargement...')"
        :headers="headers"
        :items="items"
        :option.sync="pagination"
        :server-items-length="total"
        item-key="id"
        dense
        fixed-header
        height="500"
        :loading="loading"
        :footer-props="{ showFirstLastPage, showCurrentPage }"
      />
    </v-card>
  </div>
</template>
<script>

  export default {
    data () {
      return {
        isSidebar: false,
        filter: {
          search: '',
          dateTo: '',
          dateFrom: '',
        },
        dateMenu1: false,
        dateMenu2: false,
        activePicker: null,
        page: 0,
        rowsPerPage: 10,
        pagination: { rowsPerPage: 10, page: 1, sortDesc: [true] },
        item: {},
        showFirstLastPage: true,
        showCurrentPage: true,
        items: [],
        disabled: true,
        selected: [],
        total: 0,
        dialog: false,
        loading: false,
        timeout: null,
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],
        search: '',
        dateTo: '',
        dateFrom: '',
        confirm_dialog: false,
        loadingDelete: false,
        snackbar: { active: false, text: '', color: 'info' },
        headers: [
          { text: 'NOM', value: 'product.name' },
          { text: 'QUANTITE', value: 'qty' },
          { text: 'MOUVEMENT', value: 'mouvement' },
          { text: 'STOCK PHYSIQUE', value: 'stock_physic' },
          { text: 'DATE', value: 'created_at' },

        ],
      }
    },

    computed: {
      pages () {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        ) return 0

        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      },
      params () {
        return {
          ...this.pagination,
          page: this.pagination.page,
          query: this.search,
          query1: (this.filter.dateTo && this.filter.dateFrom),
        }
      },
    },

    watch: {
      pagination: {
        handler () {
          console.log('test')

          if (this.timeout) {
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(() => {
            this.getData().then(data => {
              this.items = data.items
              this.total = data.total
              this.loading = false
            })
          }, 1500)
        },
        deep: true,
      },
      params: {
        handler () {
          console.log('test')

          if (this.timeout) {
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(() => {
            this.getData().then(data => {
              this.items = data.items
              this.total = data.total
              this.loading = false
            })
          }, 1500)
        },
        deep: true,
      },
      dialog (val) {
        val || this.close()
      },
    },
    mounted () {
      this.url = this.$route.path
      this.getData().then(data => {
        this.items = data.items
        this.total = data.total

      // if (this.page + 1 <= this.Pages) this.page += 1
      })
    },

    methods: {
      nextPage () {
        if (this.page + 1 <= this.Pages) {
          this.getData().then(data => {
            for (var i = 0; i < data.items.length; i++) {
              var index = this.items.findIndex(e => e.id === data.items[i].id)
              if (index === -1) {
                this.items.push(data.items[i])
              } else {
                this.items[index] = data.items[i]
              }
            }
            // this.items.push(...data.items)

            this.total = data.total
            if (this.page + 1 <= this.Pages) this.page += 1
          })
        }
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },
      getData () {
        console.log('data')
        this.loading = true
        return new Promise((resolve, reject) => {
          console.log(this.pagination)
          axios.get('/stocklog/list', {
            params: {
              name: this.filter.search,
              date_to: this.filter.dateTo,
              date_from: this.filter.dateFrom,
              orderBy: this.pagination.sortBy ? this.pagination.sortBy[0] : 'id',
              direction: (this.pagination.sortDesc) ? (this.pagination.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
              page: this.page + 1,
              per_page: this.pagination.rowsPerPage,
            },
          })
            .then(res => {
              const items = res.data.data
              const total = res.data.total
              this.loading = false
              resolve({
                items,
                total,
              })
            })
            .catch(err => console.log(err))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.loading = false)
        })
      },
      editItem (item) {
        this.item = item
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
    },
  }

</script>
